// extracted by mini-css-extract-plugin
export var container = "Terms-module--container--2G5wg";
export var content = "Terms-module--content--309DV";
export var title = "Terms-module--title--aQNwV";
export var subtitle = "Terms-module--subtitle--FGp9o";
export var text = "Terms-module--text--1p6GU";
export var text2 = "Terms-module--text2--1km5f";
export var textGray = "Terms-module--textGray--GKrID";
export var textGray2 = "Terms-module--textGray2--2f3Uu";
export var textEmail = "Terms-module--textEmail--2TOlw";
export var buttonToTop = "Terms-module--buttonToTop--3GnVb";
export var buttonToTopHidden = "Terms-module--buttonToTopHidden--3coHy";
export var footerContainer = "Terms-module--footerContainer--oMeik";